import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { requestSensor} from '../actions';
import { lastSensorUpdate, selectedSensor, selectedSensorExists, selectedSensorLoading } from '../selectors/sensorSelectors';
import Paper from '@material-ui/core/Paper';
import { Grid, CircularProgress } from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
// import MeasurementDates from '../components/SensorMeasurements/MeasurementDates';
import ChartConfigurator from '../components/SensorDetail/Charts/ChartConfigurator'
import MeasurementsPanel from '../components/SensorMeasurements/MeasurementsPanel'
import ExportMonthlyData from '../components/SensorMeasurements/ExportMonthlyData';
import {useTranslation} from 'react-i18next';
import {NotFound} from '../common/NotFound';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  paperHeader: {
    fontSize: 18,
    padding: theme.spacing(2),
  },
  loading: {
    margin: "auto",
  },
}));

const SensorMeasurementsPage = ({ match }) => {
  
  const isLoading = useSelector(selectedSensorLoading);
  const exists = useSelector(selectedSensorExists);

  const lastSelectedSensorUpdate = useSelector(lastSensorUpdate);
  const shouldFetch = lastSelectedSensorUpdate === null || moment().subtract(15, 'minutes').isAfter(lastSelectedSensorUpdate);
  
  const classes = useStyles();
  const {t} = useTranslation();
  
  const sensorRefId = match && match.params && match.params.id;
  const dispatch = useDispatch();
  
  const sensorDetail = useSelector(selectedSensor);


  useEffect(() => {
    if(!exists || shouldFetch){
      dispatch(requestSensor(sensorRefId));
    }    
  },[dispatch,exists,sensorRefId,shouldFetch])

  
  const render_sensor_measurements = () => {
    return (<>
      <Typography variant="h4" gutterBottom component="h2">{t('Measurements of')} {sensorDetail.name || sensorDetail.id}</Typography>
      <Grid className={classes.root} container spacing={3}>
        <Grid item xs={12} md={4} lg={4} >
          <Grid className={classes.root} container spacing={3}>
            <Grid item xs={12} md={12} lg={12} >
              <Paper className={classes.paper}>
                <ChartConfigurator />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12} >
              <Paper className={classes.paper}>
                <ExportMonthlyData />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={8} lg={8}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperHeader} variant="h4">{t('Sensor readings')}</Typography>
            <Divider />
            <MeasurementsPanel />
          </Paper>
        </Grid>
      </Grid>
    </>)
  }

  if(exists)
    return render_sensor_measurements();

  if(isLoading)
    return <CircularProgress className={classes.loading}/>

  return <NotFound />
}

export default SensorMeasurementsPage;