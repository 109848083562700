import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { requestSensor, setSensorSelected } from '../actions';
import { lastSensorUpdate, selectedSensor, selectedSensorExists, selectedSensorLoading } from '../selectors/sensorSelectors';
import { Grid, Paper, Divider, CircularProgress } from '@material-ui/core'
import EventsConfigurator from '../components/SensorEvents/EventsConfigurator'
import EventsPanel from '../components/SensorEvents/EventsPanel'
import {useTranslation} from 'react-i18next';
import ExportMonthlyData from '../components/SensorMeasurements/ExportMonthlyData';
import {NotFound} from '../common/NotFound';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  paperHeader: {
    fontSize: 18,
    padding: theme.spacing(2),
  },
  loading: {
    margin: "auto",
  },
}));

const SensorEventsPage = ({ match }) => {

  const isLoading = useSelector(selectedSensorLoading);
  const exists = useSelector(selectedSensorExists);

  const lastSelectedSensorUpdate = useSelector(lastSensorUpdate);
  const shouldFetch = lastSelectedSensorUpdate === null || moment().subtract(15, 'minutes').isAfter(lastSelectedSensorUpdate);

  const classes = useStyles();
  const {t} = useTranslation();
  
  const sensorRefId = match && match.params && match.params.id;
  const dispatch = useDispatch();
  
  const sensorDetail = useSelector(selectedSensor);

  useEffect(() => {
    if(!exists || shouldFetch){
      dispatch(requestSensor(sensorRefId));
    }    
  },[dispatch,exists,sensorRefId,shouldFetch])

  const render_sensor_events = () => { 
    return (
      <>
        <Typography variant="h4" gutterBottom component="h2">{t("Events of")} {sensorDetail.name || sensorDetail.id}</Typography>
        <Grid className={classes.root} container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid className={classes.root} container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <EventsConfigurator />
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <ExportMonthlyData measurements={false} events={true}/>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Paper className={classes.paper}>
              <Typography className={classes.paperHeader} variant="h4">{t("Events")}</Typography>
              <Divider />
              <EventsPanel />
            </Paper>
          </Grid>
        </Grid>
      </>)
    }

    if(exists)
      return render_sensor_events();

    if(isLoading)
      return <CircularProgress className={classes.loading}/>

    return <NotFound />
 
}

export default SensorEventsPage;