import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/Typography";
import { requestMeasurements, requestEvents } from "../../actions";
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import { MenuItem,Grid } from '@material-ui/core';
import { useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
/**
 * This is a component to export monthly reviews, type can define what kind of reviews we want to export (measurements or events)
 */
const useStyles = makeStyles(theme => ({
  dtPicker: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  form: {
    margin: theme.spacing(1),
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const ExportMonthlyData = ({measurements=true,events=false})=> {

  const [highestYear,setHighestYear] = useState(new Date().getFullYear());

  const [month,setMonth] = useState(new Date().getMonth()+1);
  const [year,setYear] = useState(highestYear);

  const [selectedStartDate, setSelectedStartDate] = useState(new Date(year,month-1,1,0,0,0,0));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(year,month,0, 23, 59, 59));
  
  const dispatch = useDispatch();
  const classes = useStyles();

  //generate last ten years array
  let years=[]
  for(let i=highestYear;i>highestYear-10;i--){
    years.push(i);
  }
    
  const {t} = useTranslation();

  const handleYearChange = (evt)=>{
    setYear(evt.target.value);

    setSelectedStartDate(new Date(evt.target.value,month-1,1,0,0,0,0));
    setSelectedEndDate(new Date(evt.target.value,month,0, 23, 59, 59))
  }

  const handleMonthChange=(evt)=>{
    setMonth(evt.target.value);

    setSelectedStartDate(new Date(year,evt.target.value-1,1,0,0,0,0));
    setSelectedEndDate(new Date(year,evt.target.value,0, 23, 59, 59));
  }

  const downloadMonthlyReport=()=>{
    measurements && dispatch(requestMeasurements(selectedStartDate,selectedEndDate));
    events && dispatch(requestEvents(selectedStartDate,selectedEndDate));        
  }

  return<>
      <Typography variant="h6">{t('Monthly data load')}</Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Select value={month}
          onChange={handleMonthChange}
          className={classes.select} fullWidth>
            <MenuItem value={1}>{t('January')}</MenuItem>
            <MenuItem value={2}>{t('February')}</MenuItem>
            <MenuItem value={3}>{t('March')}</MenuItem>
            <MenuItem value={4}>{t('April')}</MenuItem>
            <MenuItem value={5}>{t('May')}</MenuItem>
            <MenuItem value={6}>{t('June')}</MenuItem>
            <MenuItem value={7}>{t('July')}</MenuItem>
            <MenuItem value={8}>{t('August')}</MenuItem>
            <MenuItem value={9}>{t('September')}</MenuItem>
            <MenuItem value={10}>{t('October')}</MenuItem>
            <MenuItem value={11}>{t('November')}</MenuItem>
            <MenuItem value={12}>{t('December')}</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} md={12}>
          <Select value={year}
            onChange={handleYearChange}
            className={classes.select} fullWidth>
              {years.map(item=><MenuItem key={item} value={item}>{item}</MenuItem>)}
          </Select>
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" className={classes.button} size="large" fullWidth 
      onClick = {downloadMonthlyReport}>
        {t('Load data')}
      </Button>
  </>
}

export default ExportMonthlyData;